<template>
    <div class="row">
        <div class="col-12 pr-0 questionStatus mb-3">
            <b-button v-if="predict" @click="updateStatus('Predict','N')" :disabled="user_type != 'T'" class="mr-2" variant="success">Predict</b-button>
            <b-button v-if="!predict & user_type == 'T'" @click="updateStatus('Predict','Y')" :disabled="user_type != 'T'" class="mr-2" variant="outline-success">Not Predict</b-button>
            <b-button v-if="exam" @click="updateStatus('Exam','N')" :disabled="user_type != 'T'" class="mr-2" variant="warning">Exam Q</b-button>
            <b-button v-if="!exam & user_type == 'T'" @click="updateStatus('Exam','Y')" :disabled="user_type != 'T'" class="mr-2" variant="outline-warning">Not Exam Q</b-button>
            <b-button v-if="practice > 0" :disabled="'True'" class="mr-2" variant="primary">Practice * {{practice}}</b-button>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props:{
        selectedQuestion:{
                required: true
            },
            idName:{
                required: true,
                default:'question_id'
            },
            tableName:{
                required: true,
                default:''
            },
            question_id:{
                required:true
            },
            type:{
                required:false,
                default:'single'
            }
    },
    data(){
        return{
            user_type:null,
            question:this.selectedQuestion,
            id:this.question_id,
            practice:0,
            predict:false,
            exam:false
        }
    },
    methods:{
        ...mapActions({
            updateQuestionStatus: 'auth/updateQuestionStatus',
            fetchQuestionPractice:'auth/updateUserType'
        }),
        ...mapGetters({
            QuestionResponse: 'auth/UPDATEQuestionStatus',
            GetQuestionPractice:'auth/getPracticeNumber'
        }),
        updateStatus(type,ind){
            if(this.user_type!='T') return alert('You have no rights to change this feature')
            this.$confirm("Are you sure to change question status?",
            type,
            "question",
            {
                confirmButtonText: "Yes, Confirm",
                cancelButtonText: "No, cancel!"
            }
            ).then(() => {
                
            const newEntry=(!this.predict & !this.exam)? 'Yes':'No';
            const param={
                tableName:this.tableName,
                idName:this.idName,
                id:this.id,
                predicted_ind:this.question.predicted_ind,
                predicted_date:this.question.predicted_date.slice(0, 19).replace('T', ' '),
                exam_ind:this.question.exam_ind,
                title:this.question.title,
                exam_date:this.question.exam_date.slice(0, 19).replace('T', ' '),
                url:'updatequestion/status',
                type:this.type,
                ExistStatus:newEntry
            }
            if(type=='Predict')
            {
                param.predicted_ind=ind
                this.predict = (ind=='Y')?true:false
                param.predicted_date=new Date().toISOString().slice(0, 19).replace('T', ' ');
            }
            else if(type=='Exam'){
                param.exam_ind=ind
                this.exam = (ind=='Y')?true:false
                param.exam_date=new Date().toISOString().slice(0, 19).replace('T', ' ');
            }
            //console.log(param)
            this.updateQuestionStatus(param).then(()=>{
                const response=this.QuestionResponse()
                if(response.success==1){
                    this.question.predicted_ind=param.predicted_ind
                    this.question.predicted_date=param.predicted_date
                    this.question.exam_ind=param.exam_ind
                    this.question.exam_date=param.exam_date
                    //console.log(this.question)
                    this.$parent.updateSelectQuestionStatus(this.question,this.id)
                    }
                })
            });
        },
        fetchQuestionStatus(){
            //console.log(this.question)
            var userdata =this.$ls.get('user')
            this.user_type = userdata.is_admin
            this.predict=(this.question.predicted_ind =='Y')?true:false
            this.exam=(this.question.exam_ind =='Y')?true:false
            const data={
                user_id:userdata.user_id,
                id:this.id, //question id in table
                idName:this.idName,
                tableName:this.tableName,
                url:'updatequestion/practice',
            }
            this.updateQuestionStatus(data).then(()=>{
                const response=this.QuestionResponse()
                //console.log(response)
                if(response.success ==1){
                    this.practice=response.count.Practice
                }
                
            })
        },
        resetQuestionDetails(question,id){
            this.question=question
            this.id=id
            this.fetchQuestionStatus()
        }
    },
    created(){
        this.fetchQuestionStatus()
    }
}
</script>
<style scoped>
.btn.disabled, .btn:disabled {
    opacity: 0.85;
}
</style>
<style>
.questionStatus .btn{
    padding:2px 10px!important;
}
.row.mb-2 .col-6 .prepare
{
    vertical-align: bottom!important;
    display: table-cell!important;
    height: 33px!important;
}
.row.mb-2 .col-6
{
    padding-left: 2px!important;
}
</style>