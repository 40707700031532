<template>
<div class="row p-2">
    <div class="col-12 questonhelp">
        <!-- <h5 class="text-center mb-4 fancy"><span>Discussion</span>
            </h5> -->
           
    </div>
       <div class="col-12">
           <!-- <b-card no-body> -->
            
            <b-tabs
                active-nav-item-class="font-weight-bold text-uppercase text-danger"
                content-class="mt-1 p-3">
                <b-tab title="Discussion" active>
                    <b-button variant="outline-success" class="mr-2 commentsBtn" @click="sendComments()">
                        <b-icon icon="chat-dots-fill"></b-icon>
                        Comment</b-button>
                    <!-- Discussion Tab with popups -->
                    <div class="row">
                        <div class="col-12" v-if="hasDiscussion">
                            <ul v-for="(item,index) in discusionObject" :key="index">
                                <li>
                                        <h6 class="mb-2">
                                        <img src="https://www.w3schools.com/howto/img_avatar.png" class="user-thumbnail" alt="Forest">
                                        <strong >{{item.user_detail.username}}</strong>
                                        <b-button @click="sendChildComment(item.discussion_id)" variant="outline-primary" class="commentIconBtn">
                                            <!-- <b-icon icon="chat-dots-fill"></b-icon> -->
                                            Reply
                                        </b-button>
                                        <b-button v-if="item.like_status" @click="updateLikeStatus(item.discussion_id, false)" variant="primary" class="likeIconBtn">
                                            <b-icon icon="hand-thumbs-up"></b-icon>
                                        </b-button>
                                        <b-button v-else @click="updateLikeStatus(item.discussion_id, true)"  class="likeunIconBtn">
                                            <b-icon icon="hand-thumbs-up"></b-icon>
                                        </b-button>
                                        <br/>
                                        <p>{{item.parent_discussion}}
                                        </p>
                                        <p class="mt-1" style="color:#ccc">{{item.parent_inserted_date}}</p>
                                        <!-- {{item.child}} -->
                                        </h6>
                                        <hr/>
                                        <ul v-for="(citem,cindex) in item.child" :key="cindex">
                                            <li>
                                                <h6 class="mb-2">
                                                <img src="https://www.w3schools.com/howto/img_avatar.png" class="user-thumbnail" alt="Forest">
                                                <strong >{{citem.child_user.username}}</strong>
                                                <br/>
                                                <p>{{citem.child_discussion}}</p>
                                                <p class="mt-2">{{citem.child_inserted_date}}</p>
                                                </h6>
                                                <hr/>
                                            </li>
                                        </ul>
                                        
                                </li>
                            </ul>
                            <!-- <div v-if="maxDiscussionItems>slicediscusionObject.length">
                                <b-button @click="readMoreDiscussion()" block variant="primary">load more</b-button>
                            </div> -->
                        </div>
                        <div class="col-12 text-center" v-else>
                            <b-icon icon="folder" font-scale="3"></b-icon>
                            <p class="mt-2">No comments found with respect to this question.</p>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Users Answers">
                    <div class="row">
                        <div class="col-12" v-if="hasOtherUserAnswers">
                            <ul v-for="(item,index) in otherUsersAnswerObject" :key="index">
                                <li>
                                    <h6 class="mb-2">
                                        <img src="https://www.w3schools.com/howto/img_avatar.png" class="user-thumbnail" alt="Forest">
                                        <strong >{{item.user_detail.username}}</strong>
                                        <p>{{item.user_result}}</p>
                                        <div  v-if="item.user_recording !=null" class="audio-container col-12 left">
                                            <audio :src="item.user_recording" controls />
                                        </div>
                                        
                                        <div class="mt-3 mb-3" v-if="item.user_answer!=null">
                                            <p class="userAnswer">{{item.user_answer}}</p>
                                        </div>
                                        <p class="attemptDate">{{item.attempted_Date}}</p>
                                    </h6>
                                    <hr/>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 text-center" v-else>
                             <b-icon icon="folder" font-scale="3"></b-icon>
                            <p class="mt-2">No Answers found.</p>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="My Answers" >
                    <div class="row">
                        <div class="col-12" v-if="hasCurrentUserAnswers">
                            <ul v-for="(item,index) in myAnswersObject" :key="index">
                                <li>
                                    <h6 class="mb-2">
                                        <img src="https://www.w3schools.com/howto/img_avatar.png" class="user-thumbnail" alt="Forest">
                                        <p class="para">{{item.user_result}}</p>
                                        <div  v-if="item.user_recording !=null" class="audio-container col-8 left">
                                            <audio :src="item.user_recording" controls />
                                            <!-- {{item.attempted_Date}} -->
                                        </div>
                                        <div class="mt-3 mb-3" v-if="item.user_answer!=null">
                                            <p class="userAnswer">{{item.user_answer}}
                                            </p>
                                        </div>
                                        <p class="attemptDate">{{item.attempted_Date}}</p>
                                    </h6>
                                    <hr/>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 text-center" v-else>
                             <b-icon icon="folder" font-scale="3"></b-icon>
                            <p class="mt-2">Not attempted yet.</p>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
           <!-- </b-card> -->
    </div>
    <b-modal no-close-on-backdrop no-close-on-esc centered id="addComment" ref="addComment" title="Comment">
        <template>
        <div>
            <b-form-textarea
            id="textarea"
            v-model="parentDiscussion.discussion"
            placeholder="Enter comments..."
            rows="3"
            max-rows="5"
            class="setHeight"
            ></b-form-textarea>
        </div>
        <div class="mt-1">
            <b-form-group label="Comment type" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                id="btn-radios-1"
                v-model="parentDiscussion.comment_type"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
                button-variant="outline-primary"
                ></b-form-radio-group>
            </b-form-group>
        </div>
        </template>
    <template class="text-center" #modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <!-- <b-button class="left" size="md" variant="warning" @click="resetPComment()">
        Reset
      </b-button> -->
      <b-button size="md" variant="outline-info" @click="savePComment()">
        Post
      </b-button>
      <b-button size="md" variant="outline-warning" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>
    <b-modal no-close-on-backdrop no-close-on-esc centered id="addChildComment" ref="addChildComment" title="Comment">
        <template>
        <div>
            <b-form-textarea
            id="textarea"
            v-model="childDiscussion.discussion"
            placeholder="Enter comments..."
            rows="3"
            max-rows="5"
            ></b-form-textarea>
        </div>
        </template>
    <template class="text-center" #modal-footer="{ cancel }">

      <!-- Emulate built in modal footer ok and cancel button actions -->
      <!-- <b-button id="CReset" class="left" size="md" variant="warning" @click="resetCComment()">
        Reset
      </b-button> -->
      <b-button id="CSend" size="md" variant="outline-info" @click="saveCComment()">
        Post
      </b-button>
      <b-button id="CCance" size="md" variant="outline-warning" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>
</div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
export default {
    props:{
        question_id:{
            required:true,
            default:0
        },
        tableName:{
            required:true,
            default:''
        }
    },
    data(){
        return{
            usersAnswersList:null,
            otherUsersAnswerList:null,
            otherUsersAnswerObject:null,
            sliceotherUsersAnswerObject:null,
            myAnswersList:null,
            myAnswersObject:null,
            slicemyAnswersObject:null,
            questionId:this.question_id,
            userList:null,
            discussionList:null,
            discusionObject:null,
            slicediscusionObject:null,
            hasDiscussion:false,
            hasCurrentUserAnswers:false,
            hasOtherUserAnswers:false,
            currentUserId:0,
            maxDiscussionItems:2,
            maxOtherUserItems:1,
            maxCurrentUserItems:1,
            options: [
                { text: 'Discussion', value: 'Discussion' },
                { text: 'New Que', value: 'New Que' },
                { text: 'Que Error', value: 'Que Error' },
                { text: 'Response', value: 'Response' },
            ],
            parentDiscussion:{
                user_id:0,
                discussion:'',
                question_type:'',
                question_id:0,
                comment_type:'Discussion',
                url:'discussion/addDiscussion'
            },
            childDiscussion:{
                user_id:0,
                discussion:'',
                discussion_id:0,
                comment_type:'Discussion',
                url:'discussion/addSubDiscussion'
            }
        }
    },
    methods:{
        ...mapActions({
            getListMethod: 'speakmodule/fetchQuestion',
            getListMethodWithParam:'speakmodule/getTranscripty',
            saveData: 'speakmodule/saveAnswer',
            generateFileUpload:'speakmodule/generateFileUpload',
            fetchUserUsage:'auth/saveDashBoardData',
        }),
        ...mapGetters({
            listGetter: 'speakmodule/questions',
            dataResponse: 'speakmodule/AnswerResponse',
            userDetail: 'auth/user',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        resetPComment(){
            this.parentDiscussion.comment_type='Discussion'
            this.parentDiscussion.discussion='';
        },
        test(){
            //console.log('cancel P')
        },
        Cancel(){
            //console.log('cancel P')
            this.resetPComment()
            this.$refs.addComment.hide();
            this.resetPComment()
            this.$refs.addChildComment.hide();
        },
        savePComment(){
            this.saveData(this.parentDiscussion).then(()=>{
                const response = this.dataResponse()
                //console.log(response)
                if(response.success==1){
                    this.fetchDiscussion()
                    this.Cancel()
                }
            })
        },
        readMoreDiscussion(){
            this.maxDiscussionItems+=this.maxDiscussionItems
            this.slicediscusionObject=this.discusionObject.slice(1,this.maxDiscussionItems)
            //console.log(this.maxDiscussionItems)
            //console.log(this.slicediscusionObject)
        },
        readMoreOtherUserAnswers(){
            this.maxOtherUserItems+=this.maxOtherUserItems
        },
        readMoreCurrentUserAnswers(){
            this.maxCurrentUserItems+=this.maxCurrentUserItems
        },
        fetchAnswers(){
            if(this.questionId==0 | this.questionId==null){
                this.usersAnswersList=null
                this.myAnswersList=null
                this.otherUsersAnswerList=null
                return 
            }
            else{
                var param={
                question_id:this.questionId,
                tableName:this.tableName,
                url:'discussion/getUsersAnswerByQuestionId'
                }
                this.getListMethodWithParam(param).then(()=>{
                        const response=this.dataResponse().data
                        //console.log(response)
                        if(response.success==1)
                        { 
                            this.usersAnswersList=response.data
                            //console.log(this.usersAnswersList)
                            var vm=this
                            this.myAnswersList=this.usersAnswersList.filter(function (item) {
                            return item.user_id == vm.currentUserId
                            })
                            this.myAnswersList=this.myAnswersList.slice(0,10);
                            this.otherUsersAnswerList=this.usersAnswersList.filter(function (item) {
                            return item.user_id != vm.currentUserId
                            });
                            this.otherUsersAnswerList=this.otherUsersAnswerList.slice(0,10);
                            //console.log(this.myAnswersList)
                            this.myAnswersObject=this.convertAnswerArrayToObject(this.myAnswersList,'personal')
                            //console.log(this.otherUsersAnswerList)
                            this.otherUsersAnswerObject=this.convertAnswerArrayToObject(this.otherUsersAnswerList,'other')
                        }
                    });
            }
        },
        convertAnswerArrayToObject(array,ind){
            var object=[]
            var vm=this
            array.forEach(element => {
                //const index=object.findIndex(x=>x.discussion_id==element.discussion_id)
                var user=vm.userList.filter(function (item) {
                return item.user_id == element.user_id;
                })[0]
                if(user!=null)
                {
                    var recording=(element.user_recording!=null)?element.user_recording:null
                    var results=(ind=='other')?element.user_result.replace('Your Score','Score Info'):element.user_result;
                    var user_answer=(element.user_answer!=null)?element.user_answer:null;
                    var attempted_Date=this.changeDate(element.mock_attempt_dte,'shortDate')
                    var temp={
                        question_id:element.discussion_id,
                        user_detail:user,
                        user_result:results,
                        user_recording:recording,
                        user_answer:user_answer,
                        attempted_Date:attempted_Date
                    }
                    object.push(temp)
                }
            });
            if(ind=='other'){
                if(object.length>0){
                    this.hasOtherUserAnswers=true
                }
            }
            else{
                if(object.length>0){
                    this.hasCurrentUserAnswers=true
                }
            }
            //console.log(object)
            return object
        },
        sendComments(){
            this.parentDiscussion.user_id=this.currentUserId
            this.parentDiscussion.question_id=this.questionId
            this.parentDiscussion.question_type=this.tableName
            this.resetPComment()
            this.$refs.addComment.show();
        },
        sendChildComment(discussionId){
            //console.log(discussionId)
            this.childDiscussion.user_id=this.currentUserId 
            this.childDiscussion.discussion_id=discussionId
            this.resetCComment()
            this.$refs.addChildComment.show();
        },
        resetCComment(){
            //this.parentDiscussion.comment_type='Discussion'
            this.childDiscussion.discussion=''
        },
        saveCComment(){
            this.saveData(this.childDiscussion).then(()=>{
                const response=this.dataResponse()
                //console.log(response)
                if(response.success==1){
                    this.fetchDiscussion()
                    this.Cancel()
                }
            })
        },
        updateLikeStatus(discussion_id, value){
            const index=this.discusionObject.findIndex(x=>x.discussion_id==discussion_id)
            this.discusionObject[index].like_status=value
            var data={
                like_status:value,
                discussion_id:discussion_id,
                url:'discussion/updateDiscussion'
            }
            this.saveData(data).then(()=>{
                const response=this.dataResponse()
                //console.log(response)
            })
        },
        fetchDiscussion(){
            this.discusionObject=null
            if(this.questionId==0 | this.questionId==null){
                this.usersAnswersList=null
                this.myAnswersList=null
                this.otherUsersAnswerList=null
                return 
            }
            else{
                //console.log(this.questionId)
                //console.log(this.tableName)
                const data={
                    url:'discussion/getDiscussionByQuestionId',
                    question_id:this.questionId,
                    question_type:this.tableName
                }
                this.getListMethodWithParam(data).then(()=>{
                    this.fetchAnswers()
                    if(this.dataResponse()==null) return ;
                    const response=this.dataResponse().data
                    //console.log(response)
                    if(response.success==1)
                    {
                        this.discussionList=response.data
                        //console.log(this.discussionList)
                        
                        this.discusionObject=this.convertDiscussionArrayToObject(this.discussionList)
                        //console.log(this.discusionObject)
                        this.slicediscusionObject=this.discusionObject.slice(1,this.maxDiscussionItems)
                    }
                    else this.$alert(response.message)
                })
            }
        },
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("D-MM-YYYY");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        convertDiscussionArrayToObject(array){
            var object=[]
            var vm=this
            array.forEach(element => {
                this.hasDiscussion=true
                const index=object.findIndex(x=>x.discussion_id==element.discussion_id)
                var user=vm.userList.filter(function (item) {
                return item.user_id == element.user_id;
                })[0]
                if(index<0)
                {
                    var temp={
                        discussion_id:element.discussion_id,
                        user_detail:user,
                        parent_discussion:element.parent_discussion,
                        like_status:element.like_status,
                        parent_inserted_date:this.changeDate(element.parent_inserted_date,'shortDate'),
                        child:[]
                    }
                    if(element.child_discussion!=null)
                    {
                        user=vm.userList.filter(function (item) {
                        return item.user_id == element.child_user_id;
                        })[0]
                        temp.child.push({
                            child_discussion:element.child_discussion,
                            child_inserted_date:this.changeDate(element.child_inserted_date,'shortDate'),
                            child_user: user
                        })
                    }
                    ////debugger
                    object.push(temp)
                }
                else{
                    user=vm.userList.filter(function (item) {
                        return item.user_id == element.child_user_id;
                        })[0]
                    var tempSubChild={
                            child_discussion:element.child_discussion,
                            child_inserted_date:element.child_inserted_date,
                            child_user: user
                    }
                    // var tempChild=[]
                    // tempChild.push(object[index].child)
                    // tempChild.push(tempSubChild)
                    ////debugger
                    object[index].child.push(tempSubChild)
                    
                   //object[index].child.push(tempSubChild)
                }
            });
            return object
        },
        fetchUsers(){
            const usersUrl='users/getUsersIdNamePic'
            this.getListMethod(usersUrl).then(()=>{
                const response=this.listGetter();
                if(response.success==1)
                {
                    this.userList=response.data
                    this.fetchDiscussion()
                    //console.log(this.userList)
                }
                else this.$alert(response.message)

            });
        },
        updateQuestionId(newId){
            this.hasDiscussion=false
            this.hasCurrentUserAnswers=false
            this.hasOtherUserAnswers=false
            this.questionId=newId
            this.discusionObject=null
            this.currentUserId=this.$ls.get('user').user_id
            if(this.userList == null){
                this.fetchUsers()
            }
            else{
                this.fetchAnswers()
                this.fetchDiscussion()
            }
            
        }
    },
}
</script>
<style scoped>
.commentsBtn{
position: absolute;
right: 10px;
top: 0px;
}
ul{
    list-style: none!important;
}
ul ul { 
    padding: 5px 60px;
    }
ul ul li h6 img{
    width: 45px;
}
h6 strong{
    padding-left: 10px;
    font-size: 16px;
}
h6 p{
    padding-left: 60px;
    margin-top: -10px;
    text-transform: none;
    color: #4e4c4c!important;
    font-size: 15px;
    margin-bottom: 10px;
}
.commentIconBtn , .commentIconBtn:hover, .commentIconBtn:active,.commentIconBtn:focus,.commentIconBtn:active
{
    border: 0px;
    padding: 0px;
    margin-left: 10px;
    margin-bottom: 4px;
    font-size: 15px;
    color: #4CBB17!important;
    background: transparent!important;
}
.likeIconBtn, .likeIconBtn:hover, .likeIconBtn:active,.likeIconBtn:focus,.likeIconBtn:active
{
    border: 0px;
    padding: 0px;
    margin-left: 10px;
    margin-bottom: 4px;
    font-size: 15px;
    color: #16aaff!important;
    background: transparent!important;
}
.likeunIconBtn, .likeunIconBtn:hover, .likeunIconBtn:active,.likeunIconBtn:focus,.likeunIconBtn:active
{
    border: 0px;
    padding: 0px;
    margin-left: 10px;
    margin-bottom: 4px;
    font-size: 15px;
    color: #6c757d!important;
    background: transparent!important;
}
.audio-container
{
    height: 70px!important;
    padding-top: 3px!important;
    max-width: 700px!important;
    padding-left: 60px!important;
}
.audio-container.left audio{
    background: #f1f3f4!important;
}
h6 p.para{
    display: inline-block;
    padding-left: 20px!important;
}
h6 p.userAnswer{
    border: 1px dashed rgb(153, 153, 153);
    margin-left: 60px;
    padding: 10px;
    background: #ececec;
    color: #000000!important;
}
h6 p.attemptDate{
    display: block;
    padding-left: 70px;
}
</style>
<style>
.setHeight
{
    height: 87px!important;
}
</style>